@import-normalize;

* {
  padding: 0;
  margin: 0;
  font-family: Albert Sans, sans-serif;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

html, body {
  overflow-x: hidden;
  background-color: #0A0A0F;
  scroll-behavior: smooth;
}